if (document.querySelector(".parallax")) {

    window.addEventListener('scroll', throttle(parallax, 14));

    function throttle(fn, wait) {
        var time = Date.now();
        return function () {
            if ((time + wait - Date.now()) < 0) {
                fn();
                time = Date.now();
            }
        }
    }

    function parallax() {
        var scrolled = window.scrollY;
        var parallax = document.querySelector(".parallax");
        // You can adjust the 0.4 to change the speed
        var coords = (scrolled * .4) + 'px'
        parallax.style.transform = 'translateY(' + coords + ')';
    }
}

var scrollPosition = window.scrollY;
var body = document.body;

window.addEventListener('scroll', function () {

    scrollPosition = window.scrollY;

    if (scrollPosition >= 200) {
        body.classList.add('scrolled');
    } else {
        body.classList.remove('scrolled');
    }

});

document.querySelectorAll('a[href^="#"]').forEach(a => {
    a.addEventListener('click', function (e) {
        e.preventDefault();
        var href = this.getAttribute("href");
        var elem = document.querySelector(href) || document.querySelector("a[name=" + href.substring(1, href.length) + "]");
        var elemRect = elem.getBoundingClientRect();
        var absoluteElementTop = elemRect.top + window.pageYOffset;
        var middle = absoluteElementTop - (window.innerHeight / 2);
        window.scroll({
            top: middle,
            left: 0,
            behavior: 'smooth'
        });
    });
});

document.querySelectorAll('.level_1 > .submenu > .submenu').forEach(el => {
    el.addEventListener('click', function (e) {
        e.preventDefault();
        var elem = this;
        elem.classList.toggle('active')
    })
});


var sliders = document.querySelectorAll('.slider');

var scrollLeftButtons = document.querySelectorAll('.btn-left');
var scrollRightButtons = document.querySelectorAll('.btn-right');

scrollLeftButtons.forEach(function(button) {
    button.addEventListener('click', function() {
        let slider = this.closest('.slider-wrapper').querySelector('.slider');
        slider.scrollLeft -= 260; // Anpassen der Scroll-Geschwindigkeit bei Bedarf
    });
});

scrollRightButtons.forEach(function(button) {
    button.addEventListener('click', function() {
        let slider = this.closest('.slider-wrapper').querySelector('.slider');
        slider.scrollLeft += 260; // Anpassen der Scroll-Geschwindigkeit bei Bedarf
    });
});

if (document.querySelector('.ce_rsce_hero, .ce_rsce_hero_content ') == null ){
    document.querySelector('body').classList.add('whiteheader');
}

document.querySelectorAll('a.triggerCookies').forEach(el => {
    el.addEventListener('click', function (e) {
        e.preventDefault();
        cookiebar.show(true);
    })
});


function removeCookieFrames() {
    var pipeFrames = document.querySelectorAll('.cookieNotAllowed');
    for (var i = 0; i < pipeFrames.length; i++) {
        var pipeFrame = pipeFrames[i];
        if (pipeFrame) {
            pipeFrame.remove();
        }
    }

    console.log('Akzeptiert')
}


function pipedriveFrames() {
    var pipeFrames = document.querySelectorAll('.cookieNotAllowed');
    for (var i = 0; i < pipeFrames.length; i++) {
        var pipeFrame = pipeFrames[i];
        if (pipeFrame) {
            pipeFrame.remove();
        }
    }
    var pipeFrames1 = document.querySelectorAll('.pipedriveWebForms iframe');
    for (var i = 0; i < pipeFrames1.length; i++) {
        var pipeFrame1 = pipeFrames1[i];
        if (pipeFrame1) {
            pipeFrame1.remove();
        }
    }

    console.log('Abgelehnt')
    // Alle Elemente mit der Klasse '.pipedriveWebForms' auswählen
    var elements = document.querySelectorAll('.pipedriveWebForms');
    var cookiebarContent = document.querySelector('.cookiebarContentInner')
    // Schleife über alle ausgewählten Elemente
    for (var i = 0; i < elements.length; i++) {
        var element = elements[i];

        // Markup erstellen
        var markup = document.createElement('div');
        markup.className = 'cookieNotAllowed';
        markup.innerHTML = `
      <h4>Keine Cookies erlaubt</h4>
      <p>Bitte stimmen Sie der Cookie-Nutzung zu, um sich zu unserem Newsletter anmelden zu können. Hier können Ihre Cookie Einstellungen jederzeit bearbeiten.</p>
      <button class="btn btn-primary" onClick="cookiebar.show(true);">Einstellungen bearbeiten</button>
    `;

        // Neues Markup dem Element hinzufügen
        element.appendChild(markup);
    }
}
